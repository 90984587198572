.dark-theme .focus-visible {
    outline: 2px solid rgba(70, 108, 119, 0.3);
}

.dark-theme .header {
    background: black;
}

.dark-theme .search-wrapper {
    background: white;
}

.dark-theme .search {
    background: white;
}

.dark-theme .search__back-btn {
    color: inherit;
}