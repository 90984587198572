.notification {
    /* border: 1px solid #e0e0e0; */
    /* border-radius: 8px; */
    padding: 2px;
    /* margin-bottom: 15px; */
    display: flex;
    /* background-color: #f9f9f9; */
  }
  .notification img {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    object-fit: cover;
  }
  .notification-content {
    flex: 1;
    margin-left: 15px;
  }
  .notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .notification-type {
    font-weight: bold;
    color: #333;
  }
  .notification-description {
    margin-top: 5px;
    color: #383838;
  }
  .notification-time {
    text-align: right;
    color: #616161;
    font-size: 0.85em;
  }
  .activeNotification {
    background-color: #eeeeee ;
  }


  .no-notifications {
    text-align: center;
    margin-top: 50px;
  }
  
  .no-notifications img {
    width: 100px;
    opacity: 0.6;
  }
  
  .no-notifications-text {
    font-size: 18px;
    color: #264858;
    margin-top: 10px;
  }
  
  .no-notifications-subtext {
    font-size: 14px;
    color: #888;
  }