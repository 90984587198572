.dark-theme .loader {
    background: #ffffff;
}

/* .dark-theme .loader__logo-wrapper::after {
    background: linear-gradient( 90deg, rgba(18.6, 28.22, 33.4, .5) 0, rgba(18.6, 28.22, 33.4, .5) 33.33%, rgba(18.6, 28.22, 33.4, 0) 44.1%, rgba(18.6, 28.22, 33.4, 0) 55.8%, rgba(18.6, 28.22, 33.4, .5) 66.66%, rgba(18.6, 28.22, 33.4, .5));
} */

.dark-theme .loader__logo {
    fill: rgb(108, 113, 117);
}

.dark-theme .loader__progress {
    background: rgb(42, 47, 50);
    height: 5px;
    width: 100%;
    margin: 20px auto 30px;
    position: relative;
    animation: flow 2s ease-in infinite;
}

.dark-theme .loader__progress::after {
    background: rgb(12, 137, 124);
}

.dark-theme .loader__title {
    color: rgba(241, 241, 242, 0.88);
}

.dark-theme .loader__desc {
    color: rgba(241, 241, 242, 0.45)
}

@keyframes flow {
    0% {
      width: 0%;
      background-color: #1d4e64;
    }
    25%{
        background-color: #7ac580;

    }
    50%{
        background-color: #1d4e64;
        
    }
    75%{
        background-color: #7ac580;

    }

    100% {
      width: 90%;
      background-color: #1d4e64;
    }
  }