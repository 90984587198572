.dark-theme .chat__body {
  border-right-color: #3c4247;
}

.dark-theme .chat__bg {
  background: rgba(241, 241, 242, 0.92);
}

/* Chat Header Component  */

.dark-theme .chat__contact-name {
  color: rgba(255, 255, 255, 0.92);
}

.dark-theme .chat__contact-desc {
  color: rgba(241, 241, 242, 0.63);
}
.chat__contact-desc-user {
  color: #264858;
}

/* End Chat Header Component  */

/* Chat Content */

.dark-theme .chat__content {
  background: white;
  scrollbar-width: none;
  display: flex;
  flex-direction: column-reverse;
  /* z-index: 1; */
}

.dark-theme .chat__action-icon {
  color: rgb(255, 255, 255);
}

.dark-theme .chat__date {
  background: #49798d;
  color: white;
}

.dark-theme .chat__encryption-msg {
  background: rgb(32, 39, 43);
  color: rgb(250, 217, 100);
}

.dark-theme .chat__encryption-icon {
  color: rgb(250, 217, 100);
}

.dark-theme .chat__msg {
  color: rgba(241, 241, 242, 0.95);
  display: block;
  width: auto !important;
}

.dark-theme .chat__msg--sent {
  background: #49798d;
  color: white;
}

.dark-theme .chat__msg--rxd {
  background: #49798d;
  color: white;
}

.dark-theme .chat__msg-group > *:nth-child(1):not(.chat__msg--sent)::before,
.dark-theme .chat__msg--sent + .chat__msg--rxd::before {
  border-top-color: transparent;
  border-right-color: transparent;
}

.dark-theme .chat__msg-group > *:nth-child(1):not(.chat__msg--rxd)::before,
.dark-theme .chat__msg--rxd + .chat__msg--sent::before {
  border-top-color: transparent;
  border-left-color: transparent;
}

.dark-theme .chat__msg-footer {
  color: rgba(241, 241, 242, 0.63);
}

.dark-theme .chat__msg--rxd .chat__msg-options {
  background: transparent;
}

.dark-theme .chat__msg--sent .chat__msg-options {
  background: transparent;
}

.dark-theme .chat__img-wrapper .chat__msg-options {
  background: transparent;
}

/* End Chat Content */

.dark-theme .chat__footer {
  background: rgb(30, 36, 40);
}

.dark-theme .chat__scroll-btn {
  background: #49798d;
  color: white;
}

/* Emoji Tray */

.dark-theme .emojis__tab--active::after {
  background: rgb(0, 175, 156);
}

.dark-theme .emojis__tab-icon {
  color: rgba(241, 241, 242, 0.32);
}

.dark-theme .emojis__tab--active .emojis__tab-icon {
  color: rgba(241, 241, 242, 0.63);
}

.dark-theme .emojis__search {
  background: rgb(38, 45, 49);
  color: rgb(212, 213, 215);
}

.dark-theme .emojis__search::placeholder {
  color: #7d8184;
}

.dark-theme .emojis__label {
  color: rgba(241, 241, 242, 0.45);
}

/* End Emoji Tray */

/* Chat Footer Toolbar */

/* .dark-theme .chat__input-icon {
  color: rgb(130, 134, 137);
} */

.dark-theme .chat__input-icon--highlight {
  color: rgb(0, 150, 136);
}

.dark-theme .chat__input {
  background: rgb(51, 56, 59);
  color: rgb(0, 0, 0);
}

/* End Chat Footer Toolbar */

/* Chat Sidebar */

.dark-theme .chat-sidebar__header-icon {
  color: rgb(130, 134, 137);
}

.dark-theme .chat-sidebar__heading {
  color: rgba(255, 255, 255, 0.92);
}

.dark-theme .chat-sidebar__search-results {
  background: white;
  color: black;
}

.dark-theme .profile__section {
  background-color: white;
}

.dark-theme .profile__name {
  color: rgba(241, 241, 242, 0.92);
}

.dark-theme .profile__heading {
  color: rgb(0, 150, 136);
}

.dark-theme .profile__heading-icon {
  color: rgb(130, 134, 137);
}

.dark-theme .profile__action:not(:last-of-type),
.dark-theme .profile__about-item:not(:last-of-type),
.dark-theme .profile__group:not(:last-of-type) {
  border-bottom: 1px solid #3c4247;
}

.dark-theme .profile__action-text--top {
  color: rgba(241, 241, 242, 0.92);
}

.dark-theme .profile__action-text--bottom {
  color: rgba(241, 241, 242, 0.45);
}

.dark-theme .profile__about-item {
  color: rgba(241, 241, 242, 0.92);
}

.dark-theme .profile__group:hover {
  background-color: #3c4247;
}

.dark-theme .profile__group-text--top {
  color: rgba(241, 241, 242, 0.92);
}

.dark-theme .profile__group-text--bottom {
  color: rgba(241, 241, 242, 0.45);
}

.dark-theme .profile__section--danger {
  color: rgb(239, 105, 122);
}
