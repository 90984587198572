@font-face {
  font-family: "raleway";
  src: url("assets/fonts/Raleway-Light.ttf") format("truetype");
  font-weight: 300;
}

/* @font-face {
  font-family: "raleway-bold";
  src: url("assets/fonts/Raleway-Light.ttf") format("truetype");
  font-weight: 600;
} */

body {
  font-family: "raleway", sans-serif !important;
  /* overflow-x: hidden; */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.collabworx-light {
  background-color: #416b7d !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header-background {
  height: 100px;
  background-color: #efefef;
  background-color: #264858;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.header-background img {
  border-bottom-left-radius: 1.3rem;
  border-bottom-right-radius: 1.3rem;
}

.remove-group {
  display: block;
}
.img-cover {
  object-fit: cover;
}
.bg-collabworx {
  background-color: #eee;
}
.sidebarMenu {
  width: 75px;
  /* margin-top: -40px !important; */
  height: 100%;
  color: #9197b3;
  transition: 0.3s ease-in-out;
  transition-delay: 25ms;
  overflow: hidden;

  /* z-index: 2; */
  margin-left: 1.5rem;
  border-radius: .75rem;
  display: flex;

  /* position: absolute; */
}
.root_topscreen {
  padding-top: 2rem;
}

.expanded {
  width: 260px;
}
.sidebaractive {
  background-color: #0a5c7b;
  color: white;
}
.hovlink {
  background-color: white;
}
.hovlink:hover {
  background-color: #eee;
}

.navheight {
  height: 42px;
}

.App-link {
  color: #61dafb;
}

.icon-bg {
  background-color: #0a5c7b; /* Light background color */
  padding: 12px; /* Adjust padding for icon size */
}


/* T&C */
.terms-heading {
  font-size: 24px !important;
  background-color: #264858;
  border-radius: 10px 10px 0 0;
  padding: 20px;
}
.terms-sub-heading {
  font-size: 24px !important;
  margin-top: 30px;
}

/* About us*/
/* .card-header1 {
  background-color: #264858;
  border-radius: 10px 10px 0 0;
  padding: 20px;
} */

.input-radio {
  box-shadow: 0px 0px 0px 1px #6d6d6d;
  font-size: 3em;
  width: 12px;
  height: 12px;
  border: 3px solid #fff;
  background-clip: border-box;
  border-radius: 50%;
  appearance: none;
  transition: background-color 0.3s, box-shadow 0.3s;

}

.input-radio:checked {
  box-shadow: 0px 0px 0px 4px #0a5c7b;
  background-color: #0a5c7b;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/* Add your other styles here */

/* Loading animation styles */
/* Add your other styles here */

/* Loading animation styles */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Make sure it's above other content */
}

#loading-animation {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

.c-img-size-50px {
  height: 50px;
  width: 50px;
}
.xyborder {
  border-width: 1px 0px;
  border-style: solid;
}
.staticblue {
  background-color: #264858 !important;
  color: white !important;
}

.staticgreen {
  background-color: #7ac57f !important;
  color: white !important;
}
.staticactive {
  border-bottom: 2px solid #7ac57f !important;
  color: #7ac57f !important;
}
.textgreen {
  color: #7ac57f !important;
}

.bluecolor {
  color: #264858 !important;
}
.greencolor {
  color: #7ac57f !important;
}

.bluecolorhover {
  color: #264858 !important;
}
.bluecolorhover:hover {
  color: #7ac57f !important;
}

.hover-heading-color:hover .heading-color {
  color: #7ac57f !important;
}

.activecolor {
  color: #264858 !important;
}
.activecolor:active {
  color: #7ac57f !important;
}

.hovgreen {
  color: white !important;
}
.hovgreen:hover {
  color: #7ac57f !important;
}

.w-min {
  width: min-content !important;
}

.top-border {
  border-radius: .75rem 0.75rem 0rem 0rem;
  overflow: hidden;
}

.bottom-border {
  border-radius: 0px 0px .75rem .75rem !important;
  overflow: hidden;
}
.left-corner-border {
  border-radius: .75rem !important;
  overflow: hidden;
}
.right-corner-border {
  border-radius: 0px 0px 0.75rem 0px !important;
  overflow: hidden;
}

.custom-border {
  border-radius: 0px 10px 10px 0px !important;
  overflow: hidden;
}

.scrollable-table {
  overflow-x: auto !important;
}
table {
  white-space: nowrap !important;
}
th,
td {
  padding: 10px !important;
}

.scrollable-table::-webkit-scrollbar-thumb {
  background-color: #264858 !important;
  border-radius: 10px !important;
}

.scrollable-table::-webkit-scrollbar-track {
  background-color: #f1f1f1 !important;
  border-radius: 10px !important;
}

.accordian-arrow.rotate {
  transform: rotate(180deg);
}
/* th, td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:nth-child(odd) {
  background-color: #ffffff; 
} */

/* .footerstyling {
  color: white;
  width: 100%;
  z-index: 1;
} */

@media screen and (min-width: 320px) and (max-width: 767px) {
  .popup-model {
    width: 95% !important;
  }
  .footerstyling {
    z-index: -1 !important;
  }
  .projects-card {
    background-color: #f4f4f4;
    border-radius: 10px;
    border: 1px solid lightgray;
  }
}

@media screen and (min-width: 1600px) {
  .bigscreen_card {
    margin-top: -3%;
  }
}

.sidebarWrapper {
  position: relative;
}

.hamburgerMenu {
  display: none;
  cursor: pointer;
}
@media (max-width: 992px) {
  .header-background {
    height: 70px;
    background-color: #264858;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .hamburgerMenu {
    display: block;
    position: fixed !important;
    top: 1rem;
    z-index: 2;
    padding: 8px;
  }

  .sidebarMenu {
    /* display: none; */
    position: absolute;
    width: 0px;
    margin-left: 0px;
    margin-top: 0px;
    padding: 0px !important;
    height: 100vh;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    z-index: 3;
    transition: 0.3s ease-in-out;
    transition-delay: 25ms;
  }

  .expanded {
    display: block;
    position: absolute;
    width: 260px;
    padding: 1.5rem !important;
  }
  .root_topscreen {
    padding-top: 0rem;
    padding-bottom: 0px;
  }
  .userinfo {
    padding-top: 1.5rem;
    padding-left: 3rem;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
}
