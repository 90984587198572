@import url(./darktheme.css);
.home {
  background: #f8f9fa;
  height: 100%;
  flex: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 6px solid rgb(6, 215, 85);
  min-height: 600px !important;
}

.invite {
  /* height: 40px; */
  margin-bottom: 5px;
  border: "1px solid #E1E3EA";
  cursor: pointer;
  padding-left: 14px;
  background-color: #f4f4f4;
  border-radius: 10px;
  color: #000000;
  font-size: 16px;
}
.invite-selected {
  /* height: 40px; */
  margin-bottom: 5px;
  border: "1px solid #E1E3EA";
  cursor: pointer;
  padding-left: 14px;
  background-color: #e1e3ea;
  border-radius: 10px;
  color: #000000;
  font-size: 16px;
}

.invite:hover {
  border-left: 3px solid #7ac57f !important;
  border-right: 3px solid #7ac57f !important;
}
.invite-selected:hover {
  border-left: 3px solid #7ac57f !important;
  border-right: 3px solid #7ac57f !important;
}
.ProjLink {
  color: #7ac57f !important;
  margin-left: 10px;
  padding: 10px;
  border-radius: 8px;
  overflow: hidden;
}
.ProjLink:hover {
  border-bottom: 3px solid #7ac57f;
  color: #7ac57f;
}

.home__img-wrapper {
  width: 250px;
  height: 250px;
  margin-bottom: 20px;
}

.home__img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.home__title {
  color: #525252;
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 10px;
}

.home__text {
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.85rem;
  font-weight: 500;
  max-width: 500px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.home__text:first-of-type {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(74, 74, 74, 0.08);
}

.home__text:last-of-type {
  padding-top: 30px;
}

.home__icon {
  display: inline-block;
  margin-right: 5px;
}

.home__link {
  color: rgb(7, 188, 76);
}

.cm-z-index {
  z-index: 1002;
}

.cmm-z-index {
  z-index: 1004;
}

.c-z-index {
  z-index: 1003;
}

.cc-z-index {
  z-index: 1005;
}

.ccc-z-index {
  position: absolute;
  z-index: 1016;
}

.top-10 {
  top: 10% !important;
}

.start-10 {
  left: 10% !important;
}

.react-pdf__Page {
  height: 100vh;
  overflow: scroll;
}

/* .accordion-arrow {
  position: absolute;
  top: 50%;
  right: 15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 0 12px;
  border-color: transparent transparent transparent transparent;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
} */

.accordion-button[aria-expanded="true"] .accordion-arrow {
  transform: translateY(-50%) rotate(180deg);
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .c-w-100 {
    width: 100% !important;
  }
  .list-hover {
    font-size: 14px;
  }
  .chat-fileViewer {
  }
  .allmember-mobile {
    height: 70px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .c-padding {
    padding: 0px !important;
  }
  .dash {
    margin-top: 4rem;
  }
}
