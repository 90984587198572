@import url(darktheme.css);
.loader {
  background: rgb(240, 240, 240);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.spinner {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #1c4f65;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}
.loader__logo-wrapper {
  position: relative;
}



.loader__logo {
  z-index: 3;
  fill: rgb(191, 191, 191);
  width: 60px;
  height: 60px;
}

.loader__title {
  color: #525252;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.loader__desc {
  color: rgba(0, 0, 0, 0.25);
  font-size: 0.85rem;
}

.loader__icon {
  margin-right: 5px;
}

@keyframes glisten {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(500px);
  }
}


